<template>
  <div class="box-dialog">
    <el-dialog
      width="1220px"
      :title="isError ? '失败' : '导入数据'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <vxe-grid
        ref="refTable"
        border
        height="400"
        :columns="columns"
        :data="list"
        :edit-config="{ trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o' }"
      />
      <div slot="footer">
        <el-button @click="openExportEvent = false">导 出</el-button>
        <el-button v-if="isError" size="small" @click="popupCancel">取消</el-button>
        <el-button v-else size="small" type="primary" @click="popupConfirm"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    isError: {
      type: Boolean,
      refault: false
    },
    uuid: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      columns: [
        { field: 'id', width: 80, title: '客户编码' },
        {
          field: 'error',
          width: 250,
          title: '系统提示',
          slots: {
            default: ({ row }) => {
              return [<b style="color: blue">{row.error}</b>]
            }
          }
        },
        { field: 'sales_man', width: 70, title: '业务员' },
        { field: 'customer_name', width: 240, title: '客户名称' },
        { field: 'certificate_code', width: 100, title: '法人代码' },
        { field: 'linkman', width: 100, title: '联系人' },
        { field: 'phone', width: 100, title: '联系电话' },

        { field: 'address', width: 100, title: '地址' },
        { field: 'remark', width: 100, title: '备注' }
      ]
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 导出
    openExportEvent() {
      this.$refs.refTable.openExport()
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$api({
        method: 'post',
        url: '/admin/customers/saveImportExcel',
        params: {
          uuid: this.uuid
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.popupCancel()
          this.$emit('success')
          this.$message.success('导入成功')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
