<template>
  <div class="box-dialog">
    <el-dialog
      width="320px"
      title="批量替换业务员"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form size="mini">
        <el-form-item label="业务员">
          <el-select
            placeholder="可搜索查询"
            default-first-option
            clearable
            filterable
            v-model="changeCustomerId"
          >
            <el-option
              v-for="(item, index) in listData"
              :key="index"
              :label="item.nick_name"
              :value="item.account_id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button v-if="isError" size="small" @click="popupCancel">取消</el-button>
        <el-button v-else size="small" type="primary" @click="popupConfirm"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    isError: {
      type: Boolean,
      refault: false
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      changeCustomerId: '',
      listData: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getListData()
  },
  methods: {
    // 获取业务员列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList'
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.listData = res.data.page.list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
    },
    // 弹窗确认
    popupConfirm() {
      this.$api({
        method: 'post',
        url: '/admin/customers/customerNameBatchVue',
        params: {
          changeCustomerId: this.changeCustomerId,
          changeCustomerNamesId: this.list.map((item) => item.id)
        }
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.$message.success('批量编辑成功')
          this.$emit('success')
          this.selfShow = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
